import React from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Slide from '@material-ui/core/Slide';
import { KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import axiosToken from 'src/common/AxiosToken';
import { useDialog } from 'src/hooks/dialog';

const useStyles = makeStyles((theme) => ({
   dContent: {
      position: 'relative'
   }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogPackageDate({ open, close, order }) {
   const classes = useStyles();
   const dialog = useDialog();
   const [startDate, setStartDate] = useState(order?.active_package?.started_at);
   const [endDate, setEndDate] = useState(order?.active_package?.ended_at);
   const { enqueueSnackbar } = useSnackbar();

   const handleChangeDate = (date, type) => {
      if (type === 'start') {
         setStartDate(date);
      } else {
         setEndDate(date);
      }
   };

   const saveEditDate = () => {
      axiosToken
         .put(`order2/${order.id}/edit-package-duration`, { start: startDate, end: endDate })
         .then((response) => {
            console.log(response);
            enqueueSnackbar(`Edit package duration success`, {
               variant: 'success',
               autoHideDuration: 2000
            });
            close('save');
         })
         .catch((error) => {
            console.log('error', error);
            dialog.error(error);
         });
   };

   return (
      <div>
         <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={close} maxWidth="sm" fullWidth>
            <DialogTitle>Edit package duration</DialogTitle>
            <Divider />
            <DialogContent classes={{ root: classes.dContent }}>
               <Box width={'100%'} display={'flex'} my={1}>
                  <Typography variant="h6">
                     ช่วงวันที่ (เก่า): {moment(order?.active_package?.started_at).format('DD/MM/YYYY HH:mm')} - {moment(order?.active_package?.ended_at).format('DD/MM/YYYY HH:mm')}
                  </Typography>
               </Box>
               <Box display={'flex'} alignItems={'center'}>
                  <Box maxHeight="40vh" overflow="auto">
                     <KeyboardDatePicker
                        inputVariant="outlined"
                        fullWidth
                        size="small"
                        margin="dense"
                        // label="เลือกวันที่เริ่ม"
                        name="started_at"
                        value={startDate}
                        format="DD/MM/YYYY"
                        minDate={moment(new Date()).subtract(10, 'year').toISOString()}
                        maxDate={moment(new Date()).add(10, 'year').toISOString()}
                        onChange={(newValue) => handleChangeDate(newValue, 'start')}
                        views={['date']}
                     />
                  </Box>
                  <Box p={1}>
                     {" - "}
                  </Box>
                  <Box maxHeight="40vh" overflow="auto">
                     <KeyboardDatePicker
                        inputVariant="outlined"
                        fullWidth
                        size="small"
                        margin="dense"
                        // label="เลือกวันที่สิ้นสุด"
                        name="ended_at"
                        value={endDate}
                        format="DD/MM/YYYY"
                        minDate={moment(new Date()).subtract(10, 'year').toISOString()}
                        maxDate={moment(new Date()).add(10, 'year').toISOString()}
                        onChange={(newValue) => handleChangeDate(newValue, 'end')}
                        views={['date']}
                     />
                  </Box>
               </Box>
               {endDate < startDate && (
                  <Typography variant='caption' color='error'>
                     วันที่เริ่มไม่ควรอยู่หลังวันที่สิ้นสุด
                  </Typography>
               )}
            </DialogContent>
            <DialogActions>
               <Button variant="outlined" onClick={close} color="primary">
                  Close
               </Button>
               <Button variant="contained" disabled={endDate < startDate} onClick={saveEditDate} color="primary">
                  save
               </Button>
            </DialogActions>
         </Dialog>
      </div>
   );
}
